import $ from 'jquery';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.scss';

import 'ol/ol.css';
import {Map, View} from 'ol';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import XYZ from 'ol/source/XYZ';
import {fromLonLat} from 'ol/proj';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import {Circle, Fill, Stroke, Style} from 'ol/style';
import Overlay from 'ol/Overlay';
import GPX from 'ol/format/GPX';
import gpxFile from './data/gpx/bike-all.gpx';
import Coordinate from 'ol/coordinate';


document.addEventListener("DOMContentLoaded", function() { 
  var imgs = ['2008_1', '2009_1', '2011_1', '2012_1', '2012_2', '2013_1', '2014_1', '2014_2', '2015_1', '2015_2', '2015_3', '2017_1', '2018_1', , '2019_1', '2021_1', '2022_1', '2022_2', '2024_1'];
  var imgFiles = [];
  for(var i = 0; i < imgs.length; i++) {
    imgFiles[i] = new Image();
    imgFiles[i].src = './img/' + imgs[i] + '.jpg';
  }
});





var rasterLayer = new TileLayer ({
    source: new XYZ ({
        //url: 'http://tiles.maps.sputnik.ru/{z}/{x}/{y}.png',
        //attributions: "<a href='http://maps.sputnik.ru/'>Спутник</a> | &copy; Ростелеком | &copy; <a href='http://www.openstreetmap.org/copyright'>OpenStreetMap</a>"
        //url: 'http://a.tile.openstreetmap.org/{z}/{x}/{y}.png',
        //attributions: "Map data: © <a href='http://www.openstreetmap.org/copyright' target='_blank'>OpenStreetMap</a> contributors, under ODbL",
        url: 'https://tile.thunderforest.com/landscape/{z}/{x}/{y}.png?apikey=d7dd2c2400c548c28df954d3e618b25b',
        attribution: '&copy; <a href="http://www.thunderforest.com/">Thunderforest</a>, &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    })
});

var style = {
        'Point': new Style({
          image: new Circle({
            fill: new Fill({
              color: 'rgba(0,102,204,0.4)'
            }),
            radius: 6,
            stroke: new Stroke({
              color: 'rgba(0,102,204,0.6)',
              width: 1
            })
          })
        }),
        'LineString': new Style({
          stroke: new Stroke({
            color: '#000',
            width: 3
          })
        }),
        'MultiLineString': new Style({
          stroke: new Stroke({
            color: 'rgba(0,102,204,0.6)',
            width: 2
          })
        })
};



var vectorLayer = new VectorLayer({
    source: new VectorSource ({
        url: gpxFile,
        format: new GPX()
    }),
    style: function(feature) {
          return style[feature.getGeometry().getType()];
        }
 });


/* Popup Overlay */
var container = document.getElementById('popup');
var content = document.getElementById('popup-content');
var closer = document.getElementById('popup-closer');

var overlay = new Overlay({
    element: container,
    autoPan: true,
    autoPanAnimation: {
        duration: 250
    }
});

closer.onclick = function() {
    overlay.setPosition(undefined);
    closer.blur();
    return false;
};


var view = new View({
    center: fromLonLat([40, 52]),
    zoom: 4
});

const map = new Map({
  target: 'map',
  layers: [
    rasterLayer,
    vectorLayer
  ],
  overlays: [overlay],
  view: view
});


//map.on('moveend', zoom(view.getZoom()));

// change mouse cursor when over marker
map.on('pointermove', function(e) {
    var pixel = map.getEventPixel(e.originalEvent);
    var hit = map.hasFeatureAtPixel(pixel);
    document.body.style.cursor = hit ? 'pointer' : '';
});

// display popup on click
map.on('click', function(evt) {
    var feature = map.forEachFeatureAtPixel(evt.pixel, function(feature) {
        return feature;
    });
    var coordinates = map.getCoordinateFromPixel(evt.pixel);
    if (feature) {
      if (feature.getGeometry().getType() === 'Point') {
          overlay.setPosition(undefined);
      } else {
        
        var trackName = feature.get('name');
        var trackDescArr = feature.get('desc').split("#");
        var trackDescr = trackDescArr[0];
        var trackDate = trackDescArr[1];
        var trackImg = trackDescArr[2];
        var trackFlagsArr = trackDescArr[3].split(",");

        var trackFlags = '';
        for(var i = 0; i < trackFlagsArr.length; i++) {
            trackFlags += "<img src='flags/" + trackFlagsArr[i] + ".png' alt='' />";
        }
        
        var trackDist = trackDescArr[4];
        var trackDays = trackDescArr[5];
        
        var trackLink = feature.get('link');
        var trackHref = '';
        if (trackLink !== 'undefined') {
            trackHref = "<br /><a target='_blank' href='" + trackLink + "'>Читать отчёт...</a>";
        }
        
        
        overlay.setPosition(coordinates);
        content.innerHTML = "<h2>" + trackName + "</h2>"
                        + "<span class='popup-date'>" + trackDate + "</span>&nbsp;&nbsp;"
                        + "<span class='flags'>" + trackFlags + "</span>"
                        + "<div class='distance'>" + trackDist + ", " + trackDays + "</div>"
                        + "<img class='popup-img' width='100%' src='img/" 
                        + trackImg + ".jpg' alt='' /><br />" + trackDescr
                        
                        + trackHref;
      }
    } else {
        overlay.setPosition(undefined);
    }
});